.aboutme {
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.about-me-mobile {
    padding: 0 40px;
}

.perso-point {
    display: flex;
    align-items: center;
}

.perso-point img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.aboutme-desktop {
    display: grid;
    grid-template-columns: 1fr ;
    gap: 20px;
    width: 100%;
    max-width: 1440px;
    padding: 0 calc(60px + (100 - 60)*((100vw - 800px) / (1440 - 800)));
    box-sizing: border-box;
}

.aboutme-pic {
    display: none;
    justify-content: center;
    align-items: center;
}

.aboutme-pic img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid blue;
}

.aboutme-desktop p {
    font-size: 20px;
}