.footer-mobile {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 40px 40px;
    box-sizing: border-box;
    border-top: 2px solid #00BEE8;
    gap: 20px;
}

.footer-mobile a {
    text-decoration: none;
    color: white;
}



.footer-desktop {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 50px 0;
    padding: 40px 40px;
    border-top: 2px solid #00BEE8;
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
}

.footer-desktop a {
    text-decoration: none;
    color: white;
}



.copyright {
    text-align: center;
}

.footer-social {
    justify-content: center;
}

.footer-imprint {
    cursor: pointer;
}