.mobilemenu {
    display: flex;
    background-color: #434345;
    color: white;
    width: 100%;
    height: calc(100vh - var(--headerheight));
    margin: 0;
}

.menulists {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 20px;
    font-size: 24px;
}

.menulists a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}
