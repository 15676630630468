.success {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    height: 100px;
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background-color: #00BEE8;
    border-radius: 20px;
    text-align: center;
    box-shadow: 2px 0px 2px 2px rgba(0, 0, 0, 0.6);
}