/* mobile */
.project-model-headline {
    color: #4B47FF;
    text-align: center;
    margin: 0;
}

.project-model-lang {
    color: #00BEE8;
    display: flex;
    justify-content: center;
    gap: 5px;
    margin: 0;
}

.project-model-desc {
    color: white;
    justify-content: center;
    display: flex;
}

.project-model-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.project-model-button-live {
    border: none;
    border-radius: 5px;
    background-color: #4B47FF;
    color: white;
    padding: 15px 30px 15px 30px;
    cursor: pointer;
    font-size: 30px;
    width: 180px;
}

.project-model-button-live:hover {
    background-color: #00BEE8;
}

.project-model-button-live:active {
    background-color: #FFFFFF;
    color: #00BEE8;
}

.project-model-button-github {
    border: 1px solid #00BEE8;
    border-radius: 5px;
    color: white;
    background-color: var(--basic-color);
    padding: 15px 30px 15px 30px;
    cursor: pointer;
    font-size: 30px;
    width: 180px;
}

.project-model-button-github:hover {
    background-color: #00BEE8;
}

.project-model-button-github:active {
    background-color: #FFFFFF;
    color: #4B47FF;
}

.project-model-img {
    width: 48%;
}

/* Mobile */

.project-model-headline-mobile {
    font-size: calc(32px + (70 - 32) * ((100vw - 320px) / (800 - 320)));
}

.project-model-lang-mobile {
    font-size: calc(25px + (60 - 25) * ((100vw - 320px) / (800 - 320)));
}

.project-model-desc-mobile {
    font-size: calc(14px + (32 - 14) * ((100vw - 320px) / (800 - 320)));
}

/* Desktop */

.project-model-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max(calc(100% - (100 + 90) * ((100vw - 800px) /(1440 - 800))), 85%);
    margin-top: 50px;
    gap: 38px;
}

.project-model-desktop:nth-child(odd) {
    align-self: flex-start;
}

.project-model-desktop:nth-child(even) {
    align-self: flex-end;
    flex-direction: row-reverse;
}


.project-model-headline-desktop {
    font-size: 70px;
    /* font-size minimum unverify */
}

.project-model-lang-desktop{
    font-size: 25px;
    /* font-size minimum unverify */
}

.project-model-desc-desktop {
    font-size: 20px;
    text-align: justify;
}


.project-model-button-live-desktop {
    font-size: 30px;
    width: 180px;
    /* font-size minimum unverify */
}

.project-model-button-github-desktop {
    font-size: 30px;
    width: 180px;
    /* font-size minimum unverify */
}

.project-model-text {
    width: 48%
}

#project-model-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}