html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --basic-color: #141D2F;
  --headerheight: 60px;
  --vw-min: 320;
  --vw-min-px: calc(var(--vw-min) * 1px);
  --vw-max: 800;
}

#root{
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  background-color: var(--basic-color);
}

.name {
  background-image: linear-gradient(90deg, #1CE6B6, rgb(75, 71, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: min(calc(20px + (40 - 20) * ((100vw - 320px) / (800 - 320))), 50px);
}

.headlines-mobile {
    background-image: linear-gradient(90deg, rgb(0, 190, 232), rgb(75, 71, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: calc(40px + (70 - 40) * ((100vw - 320px) / (800 - 320)));
}

.headlines-desktop {
  font-size: min(calc(18px + 30 * ((100vw - 320px) / 680)), 79px);
  margin-block-start: 0;
  margin-block-end: 0;
}