.skills-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;
    color: white;
    gap: 20px;
    max-width: 1440px;
    margin-top: 40px;
}


.skills-images img {
    width: 50px;
    height: 50px;
}

.skills-images {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px 0;
}

/* Desktop View */
.skills-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    width: 100%;
    max-width: 1440px;
    color: white;
    padding: 0 calc(60px + (100 - 60)*((100vw - 800px) / (1440 - 800)));
    padding-top: 140px;
    box-sizing: border-box;
}

.skills-img-con {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1px;
    align-items: center;

}

.skills-img-con img {
    width: 70px;
    height: 70px;
}

.skills-text {
    font-size: 25px;
}

.third {
    font-size: 30px;
}