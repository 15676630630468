.contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    color: white;
}

.contacts-bottom {
    padding-bottom: 50px;
}

.contact-form-con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.contact-input-default {
    box-sizing: border-box;
    width: 100%;
    padding: 15px 25px 15px 25px;
    border-radius: 10px;
    outline: none;
    background-color: #141D2F;
    color: white;
    font-family: monospace;
    font-size: 16px;
    cursor: text;
}

.contact-input-empty{
    border: 2px solid #4B47FF;
}

.contact-input-error {
    border: 2px solid red;
}

.contact-input-green {
    border: 2px solid green;
}

.contact-input-default::placeholder {
    color: white;
}

.contact-input-default:focus {
    border: 2px solid #1CE6B6;
}



.contact-textarea {
    resize: none;
    height: 200px;
}

.contact-form-button, .contact-form-button-active{
    border: none;
    border-radius: 10px;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
}

.contact-form-button-active {
    background-color: #00BEE8;
    cursor: pointer;
}

.contact-form-button {
    background-color: #B6B6B6;
    cursor: not-allowed;
}

.color-red {
    color: red;
}

.contact-form-checkbox-con {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
}

.contact-form-checkbox-error-con {
    margin-bottom: 30px;
    height: 2em;
}

.contact-checkbox {
    content: url("../../../public/checkbox_blank.png");
    appearance: none;
    opacity: unset;
    cursor: pointer;
}

.contact-checkbox:hover {
    background-color: #797EF3;
    border-radius: 50%;
}

.contact-checkbox:checked {
    content: url("../../../public/checkbox_checked.png");
}

.contact-form-checkbox-error-message-con {
    min-height: 2em;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}


/* Desktop */

.contact-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    gap: 30px;
}

.contact-form-con-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contacts-desktop {
    gap: 30px;
    max-width: 1440px;
    padding: 100px calc(60px + (100 - 60)*((100vw - 800px) / (1440 - 800)));
    box-sizing: border-box;
}

.contacts-policy {
    cursor: pointer;
}

/* Autofill chrome */

input:-webkit-autofill, input:-webkit-autofill:focus {
    appearance: menulist-button;
    background-image: none !important;
    background-color: #141d2f !important;
    color: white!important;
}

.contacts-arrow-up {
    align-self: flex-end;
}

.contacts-arrow-up:hover rect{
    stroke: #00BEE8;
}

.contacts-arrow-up:hover {
    fill: #00BEE8;
    stroke: #00BEE8;
} 

.contacts-arrow-up-mobile {
    margin-right: 50px;
}