header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    height: var(--headerheight);
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    background-color: var(--basic-color);
    z-index: 2;
}

menu {
    display: flex;
    gap: 20px;
    font-size: 21px;
}

.header-burger {
    cursor: pointer;
}

menu a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}
