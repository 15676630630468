.projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 100px;
    width: 100%;
    max-width: 1440px;
    padding: 0 calc(60px + (100 - 60)*((100vw - 800px) / (1440 - 800)));
    padding-top: 100px;
    box-sizing: border-box;
}

.projects-list-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    width: 100%;
    box-sizing: border-box;
}

