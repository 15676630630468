.primary {
    padding: 15px 30px 15px 30px;
    background-color: #00BEE8;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-size: min(calc(15px + 30 * ((100vw - 320px) / 680)), 30px)
}

.primary:hover {
    background-color: #4B47FF;
}

.primary:active {
    background-color: #FFFFFF;
    color: #000000;
}