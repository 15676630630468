section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 min(calc(60px + (100 - 60)*((100vw - 800px) / (1440 - 800))), 100px);
    box-sizing: border-box;
    height: calc(100vh - var(--headerheight));
    color: white;
    gap: 20px;
    max-width: 1440px;
}


/* Mobile  */
.hero-pic-mobile {
    /* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
    height: 48%;
    background-image: url(./hero_bg.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 30px;
    max-width: 90%;
}

.upper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%; 
}

.middle {
    display: flex;
    height: 15%;
    align-items: center;
    font-size: calc(24px + 30 * ((100vw - 320px) / 680));
}

.brand-div {
    height: 10%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.brand-left {
    transform: rotate(-90deg);
    font-size: min(calc(18px + 30 * ((100vw - 320px) / 680)), 79px);
}

.brand-right {
    display: flex;
    flex-direction: column;
    font-size: min(calc(18px + 30 * ((100vw - 320px) / 680)), 79px);
}

.brand-desktop {
    font-size: min(calc(30px + (70 - 30) * ((100vw - 800px) / (1440 - 800))), 70px);
}

.brand-right-name {
    font-weight: bold;
}

.brand-right-job {
    color: #4B47FF;
}

.div-social {
    display: flex;
    /* gap: 20px; */
    gap: calc(20px + 40 * ((100vw - 320px) / 680));
}

.div-social img{
    height: calc(30px + 40*((100vw - 320px) / 680));
    width: calc(30px + 40*((100vw - 320px) / 680));
    cursor: pointer;
    border-radius: 50%;
}

/* Desktop */

.upper-desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%; 
}

.hero-pic-desktop {
    width: 30%;
    background-image: url(./hero_bg.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 30px;
}

.brand-div-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px
}

.lower {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}

.div-social-desktop {
    display: flex;
    gap: 30px;
    align-items: flex-end;
}

.div-social-desktop img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.scroll-down { 
    transform: rotate(90deg);
    font-size: 13px;
    justify-self: flex-start;
}